<app-layout></app-layout>
<div class="content">
  <div class="row" style="justify-content: center;">
    <!-- <d7iv class="col-6 col-lg-6 col-md-12 col-sm-12  total-counts"  routerLink="/analytics-dashboard/registered-experiencers">
        <mat-card class="total-count">
          <div class="card-content">
            <div class="card-img-container">
              <img class="card-img-top" src="../../../assets/little/SVG_LOGO/SVG_LOGO/Devices.svg" alt="Devices Icon" height="60" width="60">
            </div>
            <div class="card-body">
              <h5 class="card-title">Report</h5>
            </div>
          </div> 
        </mat-card>
    </div> -->
    <div   class="col-6 col-lg-6 col-md-12 col-sm-12  total-counts"   routerLink="/analytics-dashboard/registered-locals">
      <mat-card class="total-count" style="border: 2px solid hsla(158, 44%, 58%, 1) !important;">
        <div class="card-content">
          <div class="card-img-container">
            <img class="card-img-top" src="../../../assets/images/Group (2).png" alt="Active Age Group Icon" height="60" width="60">          </div>
          <div class="card-body">
            <h5 class="card-title">Incident</h5>
          </div>
        </div> 
      </mat-card>
  </div> 
  <div   class="col-6 col-lg-6 col-md-12 col-sm-12 total-users pt-5" style="margin-top: -45px;" routerLink="/analytics-dashboard/total-bookings">
  <!-- <div  *ngIf="mainEmailId !== 'amni@yopmail.com'"  class="col-6 col-lg-6 col-md-12 col-sm-12 total-users pt-5" style="margin-top: -45px;"> -->

    <mat-card  class="total-count" style="border: 2px solid hsla(158, 44%, 58%, 1) !important;">
      <div class="card-content">
        <div class="card-img-container">
          <img class="card-img-top" src="../../../assets/images/Objects.png" alt="Devices Icon" height="60" width="60">
        </div>
        <div class="card-body">
          <h1 style="padding-left: 72px;">{{registeredUsers}}</h1>
          <h5 class="card-title">Registered Users</h5>
        </div>
      </div> 
    </mat-card>
    
</div>
  </div>
</div>

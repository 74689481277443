import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-view',
    template: `<router-outlet></router-outlet>`
})

export class ViewsComponent implements OnInit {

    constructor() { }

    ngOnInit() {

    }
}
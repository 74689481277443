import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";
import { ManageServiceComponent } from "./views/early-subscribers/early-subscribers.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  }, 
   {
    path: "manage/earlysubscribers",
    component: ManageServiceComponent,
  },
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },
      // {
      //   path: "manage/earlysubscribers",
      //   loadChildren: () =>
      //     import("./views/early-subscribers/early-subscribers.module").then(
      //       (m) => m.ManageServiceModule
      //     ),
      // },

      {
        path: "caregiver",
        loadChildren: () =>
          import("./views/transaction-history/transaction-history.module").then(
            (m) => m.ManageCaregiversModule
          ),
      },


      {
        path: "manage/clients",
        loadChildren: () =>
          import("./views/manage-clients/manage-clients.module").then(
            (m) => m.ManageClientsModule
          ),
      },

      {
        path: "manage/interest",
        loadChildren: () =>
          import("./views/manage-interest/manage-interest-module").then(
            (m) => m.ManageInterestModule
          ),
      },
      {
        path: "manage/broadcastnotification",
        loadChildren: () =>
          import("./views/broadcast-notification/broadcast-notification.module").then(
            (m) => m.BroadcastNotificationModule
          ),
      },

      {
        path: "manage/transactionhistory",
        loadChildren: () =>
          import("./views/transaction-history/transaction-history.module").then(
            (m) => m.ManageCaregiversModule
          ),
      },

      {
        path: "manage/earlysubscribers",
        loadChildren: () =>
          import("./views/early-subscribers/early-subscribers.module").then(
            (m) => m.ManageServiceModule
          ),
      },

     



      {
        path: "manage/local",
        loadChildren: () =>
          import("./views/manage-local/manage-local.module").then(
            (m) => m.ManageLocalModule
          ),
      },
      {
        path: "manage/notifications",
        loadChildren: () =>
          import("./views/manage-local/manage-local.module").then(
            (m) => m.ManageLocalModule
          ),
      },
    

    ],
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _  from 'lodash';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        console.log(json ,"xl")
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
            console.log('worksheet',worksheet);
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            console.log(workbook,"wb")
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            console.log(workbook,"excelbuffer")
    
            
            this.saveAsExcelFile(excelBuffer, excelFileName);
          }
        
          private saveAsExcelFile(buffer: any, fileName: string): void {
            const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }

    getDashboard(data:any){
        return this.http.post(this.url + "appList/getDashboardAnalytics", data );
    }


    getDownloadAppList(){
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }


    getActiveUsers(){
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }


   

    getTotalForms(){
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data){
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount(){
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id:any){
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }

    experiencerReport(data:any){
        // return this.http.post(this.url + "user/admin/report", data);
    }

    localReport(data:any){
        // return this.http.post(this.url + "user/admin/report", data);
    }

    getManageLocalList(data:any){
        return this.http.post(this.url + "user/admin/get", data)
    }

    getReportsDataList(skip: number, limit: number, reportType: string): Observable<any> {
        let apiUrl: string;
      
        skip = Math.max(0, skip); 
        if (skip !== undefined && limit !== undefined) {
          apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}`;
        } else {
          apiUrl = `${this.url}adminRoute/reportList`;
        }
      
        return this.http.get(apiUrl);
      }

      getRegisteredDataList(skip: number, limit: number): Observable<any> {
        let apiUrl: string;
      
        skip = Math.max(0, skip); // Ensure skip is non-negative
        if (skip !== undefined && limit !== undefined) {
          apiUrl = `${this.url}adminRoute/registeredUsers?skip=${skip}&limit=${limit}`;
        } else {
          apiUrl = `${this.url}adminRoute/registeredUsers`;
        }
      
        return this.http.get(apiUrl);
      }

      // getTipDataList(skip: number, limit: number, reportType: string,searchKey:any): Observable<any> {
      //   let apiUrl: string;
      
      //   skip = Math.max(0, skip); 
      //   if (skip !== undefined && limit !== undefined) {
      //     if(searchKey) {
      //       apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}&searchKey=${searchKey}`;

      //     } else {
      //       apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}`;

      //     }
      //   } else {
      //     apiUrl = `${this.url}adminRoute/reportList`;
      //   }
      
      //   return this.http.get(apiUrl);
      // }

      getTipDataList(skip: number, limit: number, reportType: string, searchKey: any): Observable<any> {
        let apiUrl: string;
    
        skip = Math.max(0, skip);
        if (skip !== undefined && limit !== undefined) {
            if (searchKey) {
                apiUrl = `${this.url}adminRoute/incidentList?skip=${skip}&limit=${limit}&searchKey=${searchKey}`;
    
            } else {
                apiUrl = `${this.url}adminRoute/incidentList?skip=${skip}&limit=${limit}`;
    
            }
        } else {
            apiUrl = `${this.url}adminRoute/incidentList`;
        }
    
        return this.http.get(apiUrl);
    }
    
    
    getManageLocalById(data:any){
        return this.http.post(this.url + 'user/get', data)
    }
    getDashboardCount(){
        return this.http.get(this.url + "adminRoute/totalRegisteredUsers" );
    }

    deleteService(data:any){
        return this.http.post(this.url + 'adminRoute/deleteIncident', data);
    }

    
}
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/auth/services/auth.service";
import { HeaderService } from "src/app/shared/services/header.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { error } from "src/app/_helpers/error";
import { ManageService } from "./early-subscribers.service"
import * as $ from "jquery";
import { DatePipe } from '@angular/common';

import { FormArray, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PageChangedEvent } from 'ngx-bootstrap/pagination';





@Component({
  selector: "app-early-subscribers",
  templateUrl: "./early-subscribers.component.html",
  styleUrls: ["./early-subscribers.component.scss"],
})
export class ManageServiceComponent implements OnInit, OnDestroy {
  alertMessage: string = '';
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  message: string;

  id: String;
  earlySubscribersList: any = [];

  error = error.err;


  currentPage: number;
  page: number;
  hasMore: boolean;
  checked: boolean;

  totalCount: number;
  itemsPerPage: number = 10;
  searchKey: string;
  arrayObj: any;




 



  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  singalurl: any;
  selectedFile: any;

  viewData: any;
  type: any;
  name: String;
  

  submitted: boolean = false;
  servicesList: any = [];
  serviceId: any;
  roleForm: FormGroup;



  dataCount: number;
  notFound: boolean = false;




  @ViewChild("template") template: TemplateRef<any>;
  xlsx: any[];




  constructor(
    private _header: HeaderService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private toastrService: ToastrService,
    private service: ManageService,
    private authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private datepipe: DatePipe,


  ) {
    this.checked = false;

  }

  interests: any;
  lang: string = "eng";
  
  ngOnInit(): void {
   
    this._header.manageServicesHeading.next(true);
   
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });

    if (this.page) {
      this.currentPage = this.page;
    }

    // this.getearlysubscribersList(this.page, this.itemsPerPage);

  }
  ngOnDestroy() {
    this._header.manageServicesHeading.next(false);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  checkEmpty(event: any) {
    if (event.target.value == "") {
      this.searchKey = '';

      // this.getearlysubscribersList(this.page, this.itemsPerPage);
      document.getElementById('search').blur();
    }
  }

  sendMessage(): void {
    if (this.alertMessage.trim().length === 0) {
      return; // If the alertMessage is empty or contains only whitespace, do not send the request
    }

    const requestBody = {
      alertMsg: this.alertMessage
    };

    this.service.sendAlerts(requestBody).subscribe(
      (res: any) => {
        if (res.status === 200) {
          alert("Message: Alert Message sent");
        }
      },
      (error: any) => {
        console.error(error);
        alert("An error occurred while sending the alert message.");
      }
    );
  }
  
  



  notifications: any;

  

  isShow: boolean = false;

  initForm() {
    this.roleForm = this.fb.group({
      role: new FormControl("1"),
    })
  }

  // exportAsXLSX(): void {
  //   this.service.getearlysubscribersList({ "page": 1, "pageLimit": 1000 }).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.arrayObj = res.output.list;
  //       this.xlsx = this.arrayObj.map(item => {
  //         return {
  //           email: item.email,
  //           createdAt: this.datepipe.transform(new Date(item.createdAt), 'dd-MM-yyyy'),
  //         };
  //       });
  //       if (this.xlsx) {
  //         this.service.exportAsExcelFile(this.xlsx, 'export-to-excel');
  //       }
  //     }
  //   })
  //   console.log(this.xlsx);

  // }


  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    // this.getearlysubscribersList(event.page, event.itemsPerPage);
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);
  }

  searchdText: String;
  
  // getearlysubscribersList(page, pageLimit) {
    
  //   this.service.getearlysubscribersList({ "page": page, "pageLimit": pageLimit }).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.earlySubscribersList = res.output.list;
        
  //       this.totalCount = res.output.resultCount;
  //       if (this.totalCount == 0) {
  //         this.notFound = true;
  //       } else {
  //         this.notFound = false;
  //       }
  //     }
  //   })
  // }





}
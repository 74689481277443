

export const environment = {
  production: false,
  


   API_URL: "https://api.amni.info/", 
  //  API_URL: "https://stgapi-ijabo.approd.ca/", 
   










  


  
};

<app-layout></app-layout>

<div class="content">
    

    <div class="news-header">
        <!-- <button (click)="exportAsXLSX()" class="btn btn-primary " style="margin-right: 30px;
        margin-top:-10px">Export mails</button> -->
    </div>
    <div class="skill-tabs d-flex justify-content-between align-items-top">
      <div class="skill-tab-left">
        <div class="input-group mb-3">
          <!-- <input type="text" placeholder="Enter Your Message" style="height: 150px; width: 200px " class="form-control custom-input my-custom-input"> -->
          <input type="text" placeholder="Enter Your Message" class="inputStyle" >

          <div class="input-group-append">
          <button class="btn btn-primary btn-sm ml-2" style="height: 50px;margin-bottom: 10px;" (click)="sendMessage()">Send</button>
        </div>
        </div>
        <div class="table-responsive">
          <table class="table mt-2">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col"></th>
                <th scope="col">Description</th>
                <th scope="col">Date and Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <!-- <td colspan="3">
                  <input type="text" placeholder="Message" class="form-control">
                  <button class="btn btn-primary ml-2">Send</button>
                </td> -->
              </tr>
              <tr *ngFor="let early of earlySubscribersList; let i = index">
                <td>{{ (page - 1) * itemsPerPage + i + 1 }}.</td>
                <td>{{ early?.email ? early?.email : 'N/A' }}</td>
                <td>{{ early?.description ? early?.description : 'N/A' }}</td>
                <td>{{ early?.dateTime ? early?.dateTime : 'N/A' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination [boundaryLinks]="false" [totalItems]="totalCount" [itemsPerPage]="itemsPerPage"
          [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;"
          *ngIf="!notFound && (totalCount > itemsPerPage)" [maxSize]="5">
        </pagination>
      </div>
    </div>
    
      
</div>
<div class="sidebar">
  <div class="logo"><img src="../../../assets/Wareeg images/sidebarlogo1.png"
    style="height: 105px;
     width: 120px; 
    padding: 10px;
    margin-top: -14px;
    border-radius: 20px;
    " alt="logo"></div>
  <div class="header-links">

    
    <!-- SidebarComponent.html -->
<a  routerLink="/analytics-dashboard" routerLinkActive="active-link">
  <span class="sidebar-icon">
    <img class="black-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
    <img class="white-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
  </span>Dashboard
</a>

<a *ngIf="mainEmailId !== 'amni@yopmail.com'" routerLink="/manage/interest" routerLinkActive="active-link">
  <span class="sidebar-icon">
    <img class="black-icon" src="../../../assets/icons/SVGLOGO/Brodcast Notification.svg" alt="" height="24" width="24">
    <img class="white-icon" src="../../../assets/icons/SVGLOGO/Brodcast Notification.svg" alt="" height="24" width="24">
  </span>Alerts
</a>

<a *ngIf="mainEmailId !== 'amni@yopmail.com'" routerLink="/edit-profile" routerLinkActive="active-link">
  <span class="sidebar-icon">
    <img class="black-icon" src="../../../assets/icons/SVGLOGO/Chnage Password Black.svg" alt="" height="24" width="24">
    <img class="white-icon" src="../../../assets/icons/SVGLOGO/Chnage Password Black.svg" alt="" height="24" width="24">
  </span>Change Password
</a>

<a   routerLinkActive="active-link" id="logout" (click)="openModal(template)">
  <span class="sidebar-icon">
    <img class="black-icon" src="../../../assets/icons/SVGLOGO/Log Out Black.svg" alt="" height="24" width="24">
    <img class="white-icon" src="../../../assets/icons/SVGLOGO/Log Out Black.svg" alt="" height="24" width="24">
  </span>
  Logout
</a>



    
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
    <p>Are you sure you <br>want to logout?</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
      <button class="btn btn-blue" (click)="confirm()" style="background-color: hsla(158, 44%, 58%, 1);">Yes</button>
    </div>
  </div>

</ng-template>
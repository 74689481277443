import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';

@Injectable()
export class ManageInterestService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/list', data);
    }

    sendAlerts(requestBody: any): Observable<any> {
        return this.http.post(this.url + 'adminRoute/sendAlert', requestBody);
    }

    // getSendAlertList(requestBody: any): Observable<any> {
    //     return this.http.get(this.url + 'adminRoute/alertList', requestBody);
    // }

    // getSendAlertList(skip: number, limit: number): Observable<any> {
    //     const params = new HttpParams()
    //       .set('skip', skip.toString())
    //       .set('limit', limit.toString());
      
    //     return this.http.get(this.url + 'adminRoute/alertList', { params });
    //   }

    getSendAlertList(skip: number, limit: number): Observable<any> {
        let apiUrl: string;
        
        skip = Math.max(0, skip); // Ensure skip is non-negative
        
        if (skip !== undefined && limit !== undefined) {
          apiUrl = `${this.url}adminRoute/alertList?skip=${skip}&limit=${limit}`;
        } else {
          apiUrl = `${this.url}adminRoute/alertList`;
        }
        
        return this.http.get(apiUrl);
      }
      
      
      
      

    // sendAlerts(requestBody: any): Observable<any> {
    //     const url = 'https://your-api-url'; // Replace with your API URL
    //     return this.http.post(url, requestBody);
    //   }

    createInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/add', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'interest/admin/edit', data);
    }
    DeleteAlertsData(alertId: any) {
        const httpOptions = {
          headers: new HttpHeaders({}),
          body: { alertId: alertId }
        };
        return this.http.delete(`${this.url}adminRoute/deleteAlert/${alertId}`, httpOptions);
      }
      

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'user/images', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'service/create', data);
    }

    getServices(data:any){
        return this.http.post(this.url + 'service/list', data);
    }

    deleteService(data:any){
        return this.http.post(this.url + 'interest/admin/delete', data);
    }

    editService(data:any){
        return this.http.post(this.url + 'interest/admin/edit', data);
    }

    createInterest(data:any){
        return this.http.post(this.url + "interest/admin/add",data);
    }


    getInterestList(data:any){
        return this.http.post(this.url + 'interest/admin/list', data);
    }

    getInterestBySearch(data:any){
        return this.http.post(this.url + 'interest/admin/get', data);
    }

    deleteManageInterest(data:any){
        return this.http.post(this.url + "interest/admin/delete", data)
    }

}
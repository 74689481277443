import { Component, OnInit,AfterViewInit,DoCheck, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  userEmail: string; 
template: TemplateRef<any>;
  constructor( private modalService: BsModalService,
    private authService: AuthService,

    public router:Router) {}
    currentUser:any;
    mainEmailId: any
    ngOnInit(): void {
      this.currentUser = this.authService.currentUserValue;

      this.mainEmailId =  this.currentUser?.email
      this.userEmail = 'amni@yopmail.com';
    }

  
  ngDoCheck(){
  
    if(window.location.pathname.indexOf('coupon') != -1){
      document.getElementById('couponmenu').scrollIntoView();
    }else if(window.location.pathname.indexOf('coordinates') != -1){
      document.getElementById('Coordinates').scrollIntoView();
    }else if(window.location.pathname.indexOf('notifications') != -1){
      document.getElementById('notifications').scrollIntoView();
    }else if(window.location.pathname.indexOf('event-sponsors') != -1){
      document.getElementById('Sponsors').scrollIntoView();
    }else if(window.location.pathname.indexOf('treasure') != -1){
      document.getElementById('Treasure').scrollIntoView();
    }else if(window.location.pathname.indexOf('more-sections') != -1){
      document.getElementById('More-Section').scrollIntoView();
    }else if(window.location.pathname.indexOf('home-partners') != -1){
      document.getElementById('home-partners').scrollIntoView();
    }
  

  }



  // logout() {
  //   this.modalRef.hide();
  
  //   this.authService.logout({email: this.currentUser.email, password: this.currentUser.password}).subscribe(
  //     (res: any) => {
  //       console.log(res.status);
  //       if (res.status === 200) {
  //         localStorage.removeItem("currentUser");
  //         this.router.navigateByUrl("/auth/login");
  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }
  
  logout() {
    this.modalRef.hide();
    
    // this.authService.logout({email:this.currentUser.email, password: this.currentUser.password}).subscribe((res:any) =>{
    //   console.log(res.status);
    //   if(res.status == 200){
        localStorage.removeItem("currentUser");
      // }
      
      
    // });
    this.router.navigateByUrl("/auth/login");
  }
  

  modalRef: any;

  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
}

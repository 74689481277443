import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    authService
    router
    constructor(authService: AuthService, router: Router) {
        this.authService = authService;
        this.router = router;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const currentUser = this.authService.currentUserValue;
        // const value = true
        if (currentUser) {
            return true;
        };
        // if (value) {
        //     return true;
        // };

        this.authService.logout().subscribe();

        // not logged in so redirect to login page 
        this.router.navigate(['auth', 'login']);
        return false;
    }
}